import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, subDays, isSameDay } from 'date-fns';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { listCarrierTransactionsService } from '@/fleet/services/transactions';
import {
  currencyFormatter,
  formatValue,
} from '@/fleet/components/helpers/currencyFormatter';
import ErrorAlert from '@/fleet/components/common/alerts/error';
import SuccessAlert from '@/fleet/components/common/alerts/success';

import ExportTransactionsModal from '@/fleet/components/views/transactions/ExportTransactionsModal';
import { useLazyQuery } from '@apollo/client';
import { EMAIL_RECEIPT } from '../../services/graphql/queries/getEmailReceipt';
import toast from 'react-hot-toast';
import { EmailReceiptModal } from '../../components/views/transactions/EmailReceiptModal';
import AuthService from '../../services/auth';

const StyledButton = ({
  children,
  inverted = false,
  onClick,
  style,
  disabled,
}: StyleButtonProps) => (
  <Button
    onClick={onClick}
    sx={{
      height: 40,
      textTransform: 'none',
      // @TODO: find hex value
      background: inverted ? '#fff' : '#181818',
      color: inverted ? '#000' : '#fff',
      '&:hover': {
        backgroundColor: inverted ? 'light-gray' : '#353535',
      },
      ':disabled': {
        background: '#ccc',
      },
      padding: '15px',
      ...style,
    }}
    disabled={disabled}
  >
    {children}
  </Button>
);

interface StyleButtonProps {
  children: any;
  onClick: any;
  inverted?: boolean;
  style?: any;
  disabled?: boolean;
}

type TransactionType = {
  date: string;
};

export enum RECIPIENT {
  USER = 'user',
  DRIVER = 'driver',
  OTHER = 'other',
}

export type RecipientType =
  | RECIPIENT.USER
  | RECIPIENT.DRIVER
  | RECIPIENT.OTHER
  | null;

const CustomToolbarSelect = ({
  selectedRows,
  displayData,
  setTransactionIdsToBulkEmail,
  setShowEmailReceiptModal,
}: any) => {
  useEffect(() => {
    const updateTransactionIds = async () => {
      const txnIds = selectedRows.data.map((row: any) => {
        const index = row.index;
        return displayData[index].data[14];
      });

      setTransactionIdsToBulkEmail((prevTxnIds: string[]) => {
        if (JSON.stringify(prevTxnIds) !== JSON.stringify(txnIds)) {
          return txnIds;
        }
        return prevTxnIds;
      });
    };

    updateTransactionIds();
  }, [selectedRows, displayData]);

  return (
    <Box mr={2} my={2}>
      <StyledButton onClick={() => setShowEmailReceiptModal('selected')}>
        Email Selected Receipts
      </StyledButton>
    </Box>
  );
};

export function Transactions() {
  const { carrierUuid } = useParams();

  const now = new Date();
  const startDate30DaysAgo = subDays(now, 30);
  const startDate24HoursAgo = subDays(now, 1);

  const [data, setData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [hasDateChanged, setHasDateChanged] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [selectedDriverEmail, setSelectedDriverEmail] = useState('');
  const [recipient, setRecipient] = useState<RecipientType>(null);
  const [selectedTransactionId, setSelectedTransactionId] = useState('');
  const [selectedReceiptNum, setSelectedReceiptNum] = useState('');
  const [showEmailReceiptModal, setShowEmailReceiptModal] = useState<
    null | 'single' | 'selected'
  >(null);
  const [transactionIdsToBulkEmail, setTransactionIdsToBulkEmail] = useState<
    string[]
  >([]);
  const [customEmail, setCustomEmail] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [emailReceipt] = useLazyQuery(EMAIL_RECEIPT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.sendEmail === 'Success') {
        toast.success('Email sent successfully!');
      } else {
        toast.error('Unable to send email. Please try again later.');
      }
    },
  });

  const handleSendEmail = () => {
    let variables = {
      carrierUuid: carrierUuid,
      driverUuid: '',
      recipients: [customEmail ?? selectedDriverEmail].filter(Boolean),
      format: 'RECEIPT',
    };
    if (recipient === RECIPIENT.USER) {
      variables = {
        ...variables,
        recipients: [AuthService.getUserEmail()],
      };
    }

    if (showEmailReceiptModal === 'single') {
      emailReceipt({
        variables: { ...variables, transactionUuid: selectedTransactionId },
      });
    }

    if (showEmailReceiptModal === 'selected') {
      transactionIdsToBulkEmail.map((txnId) => {
        emailReceipt({
          variables: { ...variables, transactionUuid: txnId },
        });
      });
    }

    setRecipient(null);
    setShowEmailReceiptModal(null);
    setCustomEmail(null);
    setSelectedRows([]);
  };

  const handleCustomEmail = (email: string) => {
    setCustomEmail(email);
  };

  const handleApplyFilter = () => {
    if (filterStartDate && filterEndDate) {
      setLoading(true);
      listCarrierTransactionsService(
        carrierUuid ?? '',
        filterStartDate.toISOString(),
        filterEndDate.toISOString(),
      )
        .then((response) => {
          if (response.status === 200) {
            const transactions: TransactionType[] =
              response.data.transactionRecords.sort(
                (a: TransactionType, b: TransactionType) =>
                  new Date(a.date).getTime() - new Date(b.date).getTime(),
              );
            const filteredTransactions = transactions.filter(
              (txn: TransactionType) =>
                new Date(txn.date) >= filterStartDate &&
                new Date(txn.date) <= filterEndDate,
            );
            setFilteredData(filteredTransactions);
            setHasDateChanged(false);
          } else {
            setOpenError(true);
            setMessage(
              `There was an issue with 
              code:${response.data.code} loading transactions, 
              contact support.`,
            );
          }
        })
        .catch(() => {
          setOpenError(true);
          setMessage(
            'There was an issue loading transactions, contact support.',
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleRemoveFilter = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
    setFilteredData(data);
    setHasDateChanged(true);
  };

  const availableUnitNumbers = useMemo(() => {
    if (!data) return [];

    return data?.map((txn) => txn.unitNumber);
  }, [data]);

  const cuuid = carrierUuid !== undefined ? carrierUuid.toString() : '';

  useEffect(() => {
    listCarrierTransactionsService(
      cuuid,
      startDate30DaysAgo.toISOString(),
      now.toISOString(),
    )
      .then((response) => {
        if (response.status === 200) {
          const transactions: TransactionType[] =
            response.data.transactionRecords.sort(
              (a: TransactionType, b: TransactionType) =>
                new Date(a.date).getTime() - new Date(b.date).getTime(),
            );
          setData(transactions);

          const transactionsLast24Hours = transactions.filter(
            (txn: TransactionType) =>
              new Date(txn.date) >= startDate24HoursAgo &&
              new Date(txn.date) <= now,
          );

          if (transactionsLast24Hours.length > 0) {
            setFilterStartDate(startDate24HoursAgo);
            setFilterEndDate(now);
            setFilteredData(transactionsLast24Hours);
          } else {
            setFilterStartDate(startDate30DaysAgo);
            setFilterEndDate(now);
            setFilteredData(transactions);
          }
        } else {
          setOpenError(true);
          setMessage(
            `There was an issue with 
            code:${response.data.code} loading transactions, 
            contact support.`,
          );
        }
      })
      .catch(() => {
        setOpenError(true);
        setMessage('There was an issue loading transactions, contact support.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (filterStartDate && filterEndDate) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [filterStartDate, filterEndDate]);

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Date',
      name: 'date',
      options: {
        customBodyRender: (value) => format(Date.parse(value), 'MM/dd/yyyy'),
      },
    },
    {
      label: 'Time',
      name: 'time',
      options: {
        customBodyRender: (value) => {
          const inputTimeString = value.replace(/[^\d:]/g, ''); // Remove all timezone declarations
          const fixedDate = new Date('2000-01-01T' + inputTimeString);

          // Display local time
          const formattedTime = fixedDate.toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          });

          return formattedTime;
        },
      },
    },
    {
      label: 'Driver',
      name: 'driverName',
      options: {
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
    },
    {
      label: 'Tractor',
      name: 'unitNumber',
    },
    {
      label: 'Station',
      name: 'truckStopName',
    },
    {
      label: 'City',
      name: 'city',
    },
    {
      label: 'State',
      name: 'region',
    },
    {
      label: '#',
      name: 'transactionNumber',
      options: {
        customBodyRender: (value) => value.substring(11),
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      label: 'Product',
      name: 'productName',
    },
    {
      label: 'Qty',
      name: 'quantity',
      options: {
        customBodyRender: (value) => Number(value.value).toFixed(3),
      },
    },
    {
      label: 'Unit Price',
      name: 'unitDiscountedCost',
      options: {
        customBodyRender: (row) => {
          return currencyFormatter.format(Number(row.value));
        },
      },
    },
    {
      label: 'Discounted Total',
      name: 'productDiscountedTotal',
      options: {
        customBodyRender: (row) => {
          return currencyFormatter.format(Number(row.value));
        },
      },
    },
    {
      label: 'Total Savings',
      name: 'discountTotal',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const totalSavings = filteredData[dataIndex]?.discountTotal?.value;
          return totalSavings ? formatValue(totalSavings) : '-';
        },
      },
    },
    {
      name: 'driverEmail',
      options: {
        display: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      label: ' ',
      name: 'transactionId',
      options: {
        display: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      label: ' ',
      name: 'transactionId',
      options: {
        viewColumns: false,
        customBodyRender: () => {
          return (
            <StyledButton
              onClick={() => setShowEmailReceiptModal('single')}
              style={{ minWidth: 140 }}
            >
              Email Receipt
            </StyledButton>
          );
        },
      },
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Box
        marginBottom={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Box display="flex">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box marginRight={1}>
                <DatePicker
                  label="Start date"
                  disableFuture
                  maxDate={filterEndDate}
                  value={filterStartDate}
                  onChange={(newValue) => {
                    const same =
                      newValue &&
                      filterStartDate &&
                      isSameDay(newValue, filterStartDate);

                    setFilterStartDate(newValue);
                    if (filterStartDate && filterEndDate && !same) {
                      setHasDateChanged(true);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                  disabled={loading}
                />
              </Box>
              <Box marginRight={1}>
                <DatePicker
                  label="End date"
                  disableFuture
                  minDate={filterStartDate}
                  value={filterEndDate}
                  onChange={(newValue) => {
                    const same =
                      newValue &&
                      filterEndDate &&
                      isSameDay(newValue, filterEndDate);
                    setFilterEndDate(newValue);
                    if (filterStartDate && filterEndDate && !same) {
                      setHasDateChanged(true);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                  disabled={loading}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <StyledButton
              disabled={disableButton}
              onClick={hasDateChanged ? handleApplyFilter : handleRemoveFilter}
            >
              {hasDateChanged ? 'Apply Filter' : 'Remove Filter'}
            </StyledButton>
          </Box>
        </Box>
        <StyledButton onClick={() => setOpen(true)} style={{ px: 5 }}>
          Export
        </StyledButton>
      </Box>
      <MUIDataTable
        columns={columns}
        data={loading ? [] : filteredData}
        title="Transactions"
        options={{
          onRowClick: (row) => {
            setSelectedReceiptNum(row[7]);
            setSelectedDriverEmail(row[13]);
            setSelectedTransactionId(row[14]);
          },
          onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
            setSelectedRows(allRowsSelected.map((row) => row.dataIndex));
          },
          rowsSelected: selectedRows,
          enableNestedDataAccess: '.',
          customToolbarSelect: (selectedRows, displayData) => (
            <CustomToolbarSelect
              selectedRows={selectedRows}
              displayData={displayData}
              setTransactionIdsToBulkEmail={setTransactionIdsToBulkEmail}
              setShowEmailReceiptModal={setShowEmailReceiptModal}
            />
          ),
          isRowSelectable: (dataIndex, selectedRows) => {
            const rows = selectedRows?.data.map((row) => row.index);

            if (rows?.includes(dataIndex)) {
              return true;
            }
            if (selectedRows && selectedRows?.data?.length < 10) {
              return true;
            }

            return false;
          },
          selectableRowsHeader: false,
          filter: false,
          download: false,
          print: false,
          rowsPerPageOptions: [10, 20, 50],
          textLabels: {
            body: {
              noMatch: loading ? (
                <CircularProgress
                  sx={{
                    color: '#181818',
                  }}
                />
              ) : (
                'No transactions found'
              ),
            },
          },
        }}
      />
      <SuccessAlert
        message={message}
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
      />
      <ErrorAlert
        message={message}
        open={openError}
        handleClose={() => setOpenError(false)}
      />
      {open && (
        <ExportTransactionsModal
          handleClose={() => setOpen(false)}
          availableUnitNumbers={availableUnitNumbers}
        />
      )}
      {showEmailReceiptModal && (
        <EmailReceiptModal
          handleClose={() => setShowEmailReceiptModal(null)}
          receiptNum={selectedReceiptNum}
          recipient={recipient}
          setRecipient={setRecipient}
          selectedDriverEmail={selectedDriverEmail}
          handleSendEmail={handleSendEmail}
          showEmailReceiptModal={showEmailReceiptModal}
          transactionIdsToBulkEmail={transactionIdsToBulkEmail}
          handleCustomEmail={handleCustomEmail}
          customEmail={customEmail}
        />
      )}
    </div>
  );
}
